<template>
  <v-autocomplete
    v-bind="$attrs"
    v-model:search-input="searchInput"
    class="target-speciality-select"
    :model-value="modelValue"
    :items="targetSpecialities"
    :loading="loading"
    item-value="id"
    item-title="parent_speciality.name"
    data-cy="targetSpecialitySelect"
    variant="outlined"
    :menu-props="{ width: 800 }"
    :custom-filter="customFilter"
    clear-on-select
    @update:model-value="inputTargetSpeciality($event)"
  >
    <template #item="{ item, props }">
      <v-list-item
        v-bind="props"
        title=""
      >
        <div v-if="item.raw.child_speciality">
          <v-list-item-subtitle class="pa-0 text-truncate">
            {{ item.raw.parent_speciality.name }}
          </v-list-item-subtitle>
          <v-list-item-title class="pa-0">{{ item.raw.child_speciality.name }}</v-list-item-title>
        </div>
        <v-list-item-title v-else>{{ item.raw.parent_speciality.name }}</v-list-item-title>
      </v-list-item>
    </template>
    <template #selection="{ item }">
      <v-list-item class="pa-0">
        <div v-if="item.raw.child_speciality">
          <v-list-item-subtitle class="pa-0 text-truncate">
            {{ item.raw.parent_speciality.name }}
          </v-list-item-subtitle>
          <v-list-item-title class="pa-0">{{ item.raw.child_speciality.name }}</v-list-item-title>
        </div>
        <v-list-item-title v-else>{{ item.raw.parent_speciality?.name }}</v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: [String, Object, Array],
      default: null,
    },
    filterFacilities: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      targetSpecialities: [],
      loading: false,
      searchInput: null,
    }
  },
  async mounted() {
    this.loading = true
    const requestConfig = {
      fields: ['id', 'parent_speciality.name', 'child_speciality.name'],
      sort: ['sort'],
    }
    if (this.filterFacilities) {
      const user = await this.$cms.request(
        this.$readMe({
          fields: [
            'id',
            'facilities.id',
            'facilities.facility_id.id',
            'facilities.facility_id.specialities.id',
            'facilities.facility_id.specialities.speciality_id',
          ],
          deep: {
            facilities: {
              _filter: {
                type: {
                  _eq: 'default',
                },
              },
            },
          },
        })
      )
      const specialityIds = user?.facilities?.flatMap((f) =>
        f.facility_id?.specialities.map((s) => s.speciality_id)
      )
      requestConfig.filter = {
        _or: [
          {
            parent_speciality: {
              _in: specialityIds,
            },
          },
          {
            child_speciality: {
              _in: specialityIds,
            },
          },
        ],
      }
    }
    await this.$cms
      .request(this.$readItems('target_speciality', requestConfig))
      .then((response) => {
        this.targetSpecialities = response
        this.loading = false
      })
  },
  methods: {
    inputTargetSpeciality(event) {
      this.$emit('update:modelValue', event)
      this.searchInput = null
    },
    customFilter(value, search, item) {
      const searchLowercased = search.toLowerCase()
      const parentSpecialityLowercased = item.raw.parent_speciality.name.toLowerCase()
      const childSpecialityLowercased = item.raw.child_speciality
        ? item.raw.child_speciality.name.toLowerCase()
        : ''

      return (
        parentSpecialityLowercased.includes(searchLowercased) ||
        childSpecialityLowercased.includes(searchLowercased)
      )
    },
  },
}
</script>
<style>
.target-speciality-select {
  .v-field__input {
    padding: 0 0 0 12px;
  }
}
</style>
